import React, { useEffect } from "react"

/* Styles */
import "./index.css"

export const PreFooter = () => {
	useEffect(() => {
		const renderQuote = () => {
			// if widget.js is loaded, call the function now and once every minute
			if (typeof window !== "undefined") {
				if ('ethereumOrgRenderQuotes' in window) {
					window.ethereumOrgRenderQuotes();
					// setTimeout(renderQuote, 300000);
				} else { // try again in 100ms until successful
					setTimeout(renderQuote, 100);
				}
			}
		};

		renderQuote();
	}, []);

	return (

		<div className={'bg-pre-footer container-fluid'} >
			<div className={'pre-footer row justify-content-center align-items-center'}>
				<div className={'col-10 col-md-12 col-lg-10 col-xxl-8 px-lg-3 px-xxl-4'}>

					<div className={'ethereum-price-widget'}
						data-quote="USD">
						<span>
							Loading price from&nbsp;
							<a href="https://ethereumprice.org" target="_blank">EthereumPrice.org</a>
						</span>
					</div>

				</div>
			</div>
		</div>
	)
}
