
import React, { useEffect, useState } from "react"

import "./index.css"


export const FiltersNav = ({ location, items = '...' }) => {

	const [isScrolled, setIsScrolled] = useState(false);
	const [actualPage, setActualPage] = useState('');

	useEffect(() => {
		setActualPage(location.pathname.substr(1, location.pathname.length));
		//console.log('dentro do useEffect: ' + actualPage);

		window.addEventListener('scroll', () => {
			setIsScrolled(window.scrollY > 162);
		});

	}, []);

	return (
		<div id={'filters'}>
			<div className={'filters-navbar d-flex justify-content-center align-items-center' + (isScrolled ? ' onScroll' : '')}>
				<div className={'container-fluid'}>

					<div className={'nav1 col-12 col-md-11'}>
						<div className={'items-count d-flex align-items-center'}>
							<div>{items}</div>
							<div>items</div>
						</div>
						<label htmlFor={'toggle_filters_modal'} className={'filter-menu d-flex align-items-center'}>
							<img src={'/assets/img/icons/filters_icon.svg'} alt={'alt-icon'} />
							<div>Filters</div>
						</label>
					</div>


					<div className={'nav2 col-12 p-0 fixed'}>
						<div className={'container-fluid'}>
							<div className={'drop-count'}>
								<div className={'go-home'}>
									<a href="/">Home</a></div>
								<div className={'items-count d-flex align-items-center'}>
									<div>{actualPage}</div>
									<div>{items}</div>
								</div>
							</div>
							<label htmlFor={'toggle_filters_modal'} className={'filter-menu d-flex align-items-center'}>
								<img src={'/assets/img/icons/filters_icon.svg'} alt={'alt-icon'} />
							</label>
						</div>
					</div>


				</div>
			</div>

			{/* <div className={'filters-navbar d-flex justify-content-center align-items-center' + (isScrolled ? ' animate' : '') }>
				<div className={'container-fluid'}>



				</div>
			</div> */}
		</div>
	)

}

