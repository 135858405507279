import React from "react"
import SlickSlider from "react-slick";
import { ResponsiveImage } from "../../../components"

/* Styles */
import "/static/assets/libs/slick-carousel-1.6.0/slick.min.css";
import "./index.css"

export const Slider = ({ isHero, compWidth, compHeight }) => {

	/* console.log(isHero);
	console.log(compWidth);
	console.log(compHeight); */

	var settings = {
		/* arrows: false,*/
		dots: true,
		infinite: true,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		autoplaySpeed: 3000,
		/* pauseOnDotsHover: true,
		pauseOnFocus: true,
		pauseOnHover: true */
		/*responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		] */
	};

	return (
		<div className={'container-fluid p-0 bg-slider' + (isHero ? ' hero-top' : '')} /* style={{ height: compHeight ? compHeight : 'auto' }} */ >

			<SlickSlider {...settings} className={'slider d-flex justify-content-center align-items-start text-center'}>

				{/* Slide 1 */}
				<div className={'wrapper'}>
					<div className={'h-100 d-flex justify-content-center align-items-center'}>
						<ResponsiveImage
							initialSrc={"https://via.placeholder.com/38x38.png/05221B?text=PNG+Initial+Placeholder"}
							initialSrcSet={"https://via.placeholder.com/375x375.png/05221B?text=PNG+Image+XS 1x, https://via.placeholder.com/750x750.png/05221B?text=PNG+Image+XS@2x 2x"}
							alt={"Alt Description"}
							cssClasses={"optional css-classes as-a-string"}
							sources={[
								// XXL
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1920x1080.webp/05221B?text=WEBP+Image+XXL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3840x2160.webp/05221B?text=WEBP+Image+XXL@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:1540px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1920x1080.png/05221B?text=PNG+Image+XXL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3840x2160.png/05221B?text=PNG+Image+XXL@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:1540px)"
								},

								// XL
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1540x900.webp/05221B?text=WEBP+Image+XL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3080x1800.webp/05221B?text=WEBP+Image+XL@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:1200px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1540x900.png/05221B?text=PNG+Image+XL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3080x1800.png/05221B?text=PNG+Image+XL@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:1200px)"
								},

								// LG
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1200x1200.webp/05221B?text=WEBP+Image+LG",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/2400x2400.webp/05221B?text=WEBP+Image+LG@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:992px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1200x1200.png/05221B?text=PNG+Image+LG",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/2400x2400.png/05221B?text=PNG+Image+LG@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:992px)"
								},

								// MD
								{
									srcset: [
										{
											src: "https://via.placeholder.com/992x992.webp/05221B?text=WEBP+Image+MD",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1984x1984.webp/05221B?text=WEBP+Image+MD@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:768px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/992x992.png/05221B?text=PNG+Image+MD",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1984x1984.png/05221B?text=PNG+Image+MD@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:768px)"
								},

								// SM
								{
									srcset: [
										{
											src: "https://via.placeholder.com/768x768.webp/05221B?text=WEBP+Image+SM",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1536x1536.webp/05221B?text=WEBP+Image+SM@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:576px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/768x768.png/05221B?text=PNG+Image+SM",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1536x1536.png/05221B?text=PNG+Image+SM@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:576px)"
								},

								// Complement smallest image with WEBP variant
								{
									srcset: [
										{
											src: "https://via.placeholder.com/375x375.webp/05221B?text=WEBP+Image+XS",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/750x750.webp/05221B?text=WEBP+Image+XS@2x",
											xd: "2x",
										}
									],
									type: "image/webp"
								},
							]}
						/>
					</div>
				</div>

				{/* Slide 2 */}
				<div className={'wrapper'}>
					<div className={'h-100 d-flex justify-content-center align-items-center'}>
						<ResponsiveImage
							initialSrc={"https://via.placeholder.com/38x38.png/6335FA?text=PNG+Initial+Placeholder"}
							initialSrcSet={"https://via.placeholder.com/375x375.png/6335FA?text=PNG+Image+XS 1x, https://via.placeholder.com/750x750.png/6335FA?text=PNG+Image+XS@2x 2x"}
							alt={"Alt Description"}
							cssClasses={"optional css-classes as-a-string"}
							sources={[
								// XXL
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1920x1080.webp/6335FA?text=WEBP+Image+XXL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3840x2160.webp/6335FA?text=WEBP+Image+XXL@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:1540px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1920x1080.png/6335FA?text=PNG+Image+XXL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3840x2160.png/6335FA?text=PNG+Image+XXL@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:1540px)"
								},

								// XL
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1540x900.webp/6335FA?text=WEBP+Image+XL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3080x1800.webp/6335FA?text=WEBP+Image+XL@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:1200px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1540x900.png/6335FA?text=PNG+Image+XL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3080x1800.png/6335FA?text=PNG+Image+XL@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:1200px)"
								},

								// LG
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1200x1200.webp/6335FA?text=WEBP+Image+LG",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/2400x2400.webp/6335FA?text=WEBP+Image+LG@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:992px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1200x1200.png/6335FA?text=PNG+Image+LG",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/2400x2400.png/6335FA?text=PNG+Image+LG@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:992px)"
								},

								// MD
								{
									srcset: [
										{
											src: "https://via.placeholder.com/992x992.webp/6335FA?text=WEBP+Image+MD",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1984x1984.webp/6335FA?text=WEBP+Image+MD@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:768px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/992x992.png/6335FA?text=PNG+Image+MD",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1984x1984.png/6335FA?text=PNG+Image+MD@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:768px)"
								},

								// SM
								{
									srcset: [
										{
											src: "https://via.placeholder.com/768x768.webp/6335FA?text=WEBP+Image+SM",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1536x1536.webp/6335FA?text=WEBP+Image+SM@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:576px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/768x768.png/6335FA?text=PNG+Image+SM",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1536x1536.png/6335FA?text=PNG+Image+SM@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:576px)"
								},

								// Complement smallest image with WEBP variant
								{
									srcset: [
										{
											src: "https://via.placeholder.com/375x375.webp/6335FA?text=WEBP+Image+XS",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/750x750.webp/6335FA?text=WEBP+Image+XS@2x",
											xd: "2x",
										}
									],
									type: "image/webp"
								},
							]}
						/>
					</div>
				</div>

				{/* Slide 3 */}
				<div className={'wrapper'}>
					<div className={'h-100 d-flex justify-content-center align-items-center'}>
						<ResponsiveImage
							initialSrc={"https://via.placeholder.com/38x38.png/F7F7F4?text=PNG+Initial+Placeholder"}
							initialSrcSet={"https://via.placeholder.com/375x375.png/F7F7F4?text=PNG+Image+XS 1x, https://via.placeholder.com/750x750.png/F7F7F4?text=PNG+Image+XS@2x 2x"}
							alt={"Alt Description"}
							cssClasses={"optional css-classes as-a-string"}
							sources={[
								// XXL
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1920x1080.webp/F7F7F4?text=WEBP+Image+XXL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3840x2160.webp/F7F7F4?text=WEBP+Image+XXL@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:1540px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1920x1080.png/F7F7F4?text=PNG+Image+XXL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3840x2160.png/F7F7F4?text=PNG+Image+XXL@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:1540px)"
								},

								// XL
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1540x900.webp/F7F7F4?text=WEBP+Image+XL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3080x1800.webp/F7F7F4?text=WEBP+Image+XL@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:1200px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1540x900.png/F7F7F4?text=PNG+Image+XL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3080x1800.png/F7F7F4?text=PNG+Image+XL@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:1200px)"
								},

								// LG
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1200x1200.webp/F7F7F4?text=WEBP+Image+LG",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/2400x2400.webp/F7F7F4?text=WEBP+Image+LG@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:992px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1200x1200.png/F7F7F4?text=PNG+Image+LG",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/2400x2400.png/F7F7F4?text=PNG+Image+LG@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:992px)"
								},

								// MD
								{
									srcset: [
										{
											src: "https://via.placeholder.com/992x992.webp/F7F7F4?text=WEBP+Image+MD",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1984x1984.webp/F7F7F4?text=WEBP+Image+MD@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:768px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/992x992.png/F7F7F4?text=PNG+Image+MD",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1984x1984.png/F7F7F4?text=PNG+Image+MD@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:768px)"
								},

								// SM
								{
									srcset: [
										{
											src: "https://via.placeholder.com/768x768.webp/F7F7F4?text=WEBP+Image+SM",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1536x1536.webp/F7F7F4?text=WEBP+Image+SM@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:576px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/768x768.png/F7F7F4?text=PNG+Image+SM",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1536x1536.png/F7F7F4?text=PNG+Image+SM@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:576px)"
								},

								// Complement smallest image with WEBP variant
								{
									srcset: [
										{
											src: "https://via.placeholder.com/375x375.webp/F7F7F4?text=WEBP+Image+XS",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/750x750.webp/F7F7F4?text=WEBP+Image+XS@2x",
											xd: "2x",
										}
									],
									type: "image/webp"
								},
							]}
						/>
					</div>
				</div>

				{/* Slide 4 */}
				<div className={'wrapper'}>
					<div className={'h-100 d-flex justify-content-center align-items-center'}>
						<ResponsiveImage
							initialSrc={"https://via.placeholder.com/38x38.png/F0003A?text=PNG+Initial+Placeholder"}
							initialSrcSet={"https://via.placeholder.com/375x375.png/F0003A?text=PNG+Image+XS 1x, https://via.placeholder.com/750x750.png/F0003A?text=PNG+Image+XS@2x 2x"}
							alt={"Alt Description"}
							cssClasses={"optional css-classes as-a-string"}
							sources={[
								// XXL
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1920x1080.webp/F0003A?text=WEBP+Image+XXL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3840x2160.webp/F0003A?text=WEBP+Image+XXL@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:1540px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1920x1080.png/F0003A?text=PNG+Image+XXL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3840x2160.png/F0003A?text=PNG+Image+XXL@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:1540px)"
								},

								// XL
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1540x900.webp/F0003A?text=WEBP+Image+XL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3080x1800.webp/F0003A?text=WEBP+Image+XL@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:1200px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1540x900.png/F0003A?text=PNG+Image+XL",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/3080x1800.png/F0003A?text=PNG+Image+XL@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:1200px)"
								},

								// LG
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1200x1200.webp/F0003A?text=WEBP+Image+LG",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/2400x2400.webp/F0003A?text=WEBP+Image+LG@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:992px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/1200x1200.png/F0003A?text=PNG+Image+LG",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/2400x2400.png/F0003A?text=PNG+Image+LG@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:992px)"
								},

								// MD
								{
									srcset: [
										{
											src: "https://via.placeholder.com/992x992.webp/F0003A?text=WEBP+Image+MD",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1984x1984.webp/F0003A?text=WEBP+Image+MD@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:768px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/992x992.png/F0003A?text=PNG+Image+MD",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1984x1984.png/F0003A?text=PNG+Image+MD@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:768px)"
								},

								// SM
								{
									srcset: [
										{
											src: "https://via.placeholder.com/768x768.webp/F0003A?text=WEBP+Image+SM",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1536x1536.webp/F0003A?text=WEBP+Image+SM@2x",
											xd: "2x",
										}
									],
									type: "image/webp",
									media: "(min-width:576px)"
								},
								{
									srcset: [
										{
											src: "https://via.placeholder.com/768x768.png/F0003A?text=PNG+Image+SM",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/1536x1536.png/F0003A?text=PNG+Image+SM@2x",
											xd: "2x",
										}
									],
									type: "image/png",
									media: "(min-width:576px)"
								},

								// Complement smallest image with WEBP variant
								{
									srcset: [
										{
											src: "https://via.placeholder.com/375x375.webp/F0003A?text=WEBP+Image+XS",
											xd: "1x",
										},
										{
											src: "https://via.placeholder.com/750x750.webp/F0003A?text=WEBP+Image+XS@2x",
											xd: "2x",
										}
									],
									type: "image/webp"
								},
							]}
						/>
					</div>
				</div>

			</SlickSlider>

		</div>
	)
}
