import { useState } from 'react';
import moment from "moment";
import { API_URL } from '../../../constants';
import { formatFixed } from '@ethersproject/bignumber';

/**
 * START useCookie
 */
const getItem = (key) => {
	if (typeof document !== 'undefined') {
		return document.cookie.split(`; `).reduce((total, currentCookie) => {
			const item = currentCookie.split(`=`);
			const storedKey = item[0];
			const storedValue = item[1];

			return key === storedKey ? decodeURIComponent(storedValue) : total;
		}, ``);
	}
};

const setItem = (key, value, numberOfDays) => {
	if (typeof document !== 'undefined') {
		const now = new Date();

		// set the time to be now + numberOfDays
		now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
		document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
	}
};

/**
 *
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */
export const useCookie = (key, defaultValue) => {
	const getCookie = () => getItem(key) || defaultValue;
	const [cookie, setCookie] = useState(getCookie());

	const updateCookie = (value, numberOfDays) => {
		setCookie(value);
		setItem(key, value, numberOfDays);
	};

	return [cookie, updateCookie];
};
/**
 * END useCookie
 */


/**
 * Get Upcoming Drops
 *
 * @param {function} setterFunction Setter function from "useState"
 */
export const getUpcomingDrops = (setterFunction) => {
	fetch(`${API_URL}/drops/upcoming`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then((response) => {
			// console.log('Upcoming Drops response:', response);

			return response.json();
		})
		.then((data) => {
			console.log('Upcoming Drops data:', data);

			if (data.result) {
				let drops = { i: [] };
				for (let i = 0; i < data.result.c; i++) {
					let drop = data.result.i[i];

					// Add Days, Hours and Minutes as a time_left object
					let drop_launch = moment.utc(drop.drop_launch);
					let difference = drop_launch.diff(moment.utc());
					console.log({ difference });
					let time_left = {
						days: 0,
						hours: '00',
						minutes: '00',
					};

					if (difference > 0) {
						time_left = {
							days: Math.floor(difference / (1000 * 60 * 60 * 24)),
							hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
							minutes: Math.floor((difference / 1000 / 60) % 60),
						};
						time_left.hours = time_left.hours < 10 ? '0' + time_left.hours : time_left.hours;
						time_left.minutes = time_left.minutes < 10 ? '0' + time_left.minutes : time_left.minutes;
					}

					drop.time_left = time_left;

					// Add a formatted release date
					drop.drop_launch_date = drop_launch.format('DD MMM, YYYY');
					drop.drop_launch_time = drop_launch.format('hh[:]mm a');

					drops.i.push(drop);
				}
				console.log('Drop post-result:', { ...data.result, ...drops });
				typeof setterFunction === 'function' && setterFunction({ ...data.result, ...drops });
			} else {
				console.log('NO Upcoming Drops');
				typeof setterFunction === 'function' && setterFunction(false);
			}
		})
		.catch((e) => {
			console.error(e);
			typeof setterFunction === 'function' && setterFunction(false);
		});
}

/**
 * Get NFTs, paginated
 *
 * @param {function} setterFunction Setter function from "useState"
 * @param {object} opts Options for customizing the request
 * limit: Integer - items per page; defaults to 20
 * offset: Integer - items to skip; defaults to 0
 * order_by: String - field nickname; supported values:
		'd' => 'id',
		'n' => 'name',
		'p' => 'price',
		's' => 'num_sales',
		'r' => 'random',
 * order_direction: String - order direction; supported values: asc, desc
 */
export const getArtworks = (setterFunction, opts = {}) => {
	let params = [];

	if ('limit' in opts && opts.limit != null) {
		params.push(`limit=${opts.limit}`);
	}
	if ('offset' in opts && opts.offset != null) {
		params.push(`offset=${opts.offset}`);
	}
	if ('order_by' in opts && opts.order_by != null) {
		params.push(`order_by=${opts.order_by}`);
	}
	if ('order_direction' in opts && opts.order_direction != null) {
		params.push(`order_direction=${opts.order_direction}`);
	}
	if ('group_by' in opts && opts.group_by != null) {
		params.push(`group_by=${opts.group_by}`);
	}
	if ('group_order_by' in opts && opts.group_order_by != null) {
		params.push(`group_order_by=${opts.group_order_by}`);
	}
	if ('group_order_direction' in opts && opts.group_order_direction != null) {
		params.push(`group_order_direction=${opts.group_order_direction}`);
	}
	if ('filters' in opts && opts.filters.hasOwnProperty !== undefined) {
		for (let key in opts.filters) {
			opts.filters.hasOwnProperty(key) && opts.filters[key] != null && params.push(`filters[${key}]=${opts.filters[key]}`);
		}
	}

	fetch(`${API_URL}/artworks${params.length > 0 ? '?' + params.join('&') : ''}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then((response) => {
			// console.log('Artworks response:', response);

			return response.json();
		})
		.then((data) => {
			console.log('Artworks data:', data);

			typeof setterFunction === 'function' && setterFunction(data.result);
		})
		.catch((e) => {
			console.error(e);
			typeof setterFunction === 'function' && setterFunction(false);
		});
}


/**
 * Get Drops, paginated
 *
 * @param {function} setterFunction Setter function from "useState"
 * @param {object} opts Options for customizing the request
 * limit: Integer - items per page; defaults to 20
 * offset: Integer - items to skip; defaults to 0
 * order_by: String - field nickname; supported values:
		'dl' => 'drop_launch',
		'n' => 'name',
		'r' => 'random',
 * order_direction: String - order direction; supported values: asc, desc
 */
export const getDrops = (setterFunction, opts = {}) => {
	let params = [];

	if ('limit' in opts && opts.limit != null) {
		params.push(`limit=${opts.limit}`);
	}
	if ('offset' in opts && opts.offset != null) {
		params.push(`offset=${opts.offset}`);
	}
	if ('order_by' in opts && opts.order_by != null) {
		params.push(`order_by=${opts.order_by}`);
	}
	if ('order_direction' in opts && opts.order_direction != null) {
		params.push(`order_direction=${opts.order_direction}`);
	}
	if ('asset_limit' in opts && opts.asset_limit != null) {
		params.push(`asset_limit=${opts.asset_limit}`);
	}
	if ('asset_order_by' in opts && opts.asset_order_by != null) {
		params.push(`asset_order_by=${opts.asset_order_by}`);
	}
	if ('filters' in opts && opts.filters.hasOwnProperty !== undefined) {
		for (let key in opts.filters) {
			opts.filters.hasOwnProperty(key) && opts.filters[key] != null && params.push(`filters[${key}]=${opts.filters[key]}`);
		}
	}

	fetch(`${API_URL}/drops${params.length > 0 ? '?' + params.join('&') : ''}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then((response) => {
			// console.log('Drops response:', response);

			return response.json();
		})
		.then((data) => {
			console.log('Drops data:', data);

			typeof setterFunction === 'function' && setterFunction(data.result);
		})
		.catch((e) => {
			console.error(e);
			typeof setterFunction === 'function' && setterFunction(false);
		});
}


/**
 *
 * @param {function} setterFunction Setter function from "useState"
 * @param {object} opts Options for customizing the request
 * limit: Integer - items per page; defaults to 20
 * offset: Integer - items to skip; defaults to 0
 * order_by: String - field nickname; supported values:
		'n' => 'name',
		'nc' => 'nft_count',
		'r' => 'random',
 * order_direction: String - order direction; supported values: asc, desc
 */
export const getArtists = (setterFunction, opts = {}) => {
	let params = [];

	if ('limit' in opts && opts.limit != null) {
		params.push(`limit=${opts.limit}`);
	}
	if ('offset' in opts && opts.offset != null) {
		params.push(`offset=${opts.offset}`);
	}
	if ('order_by' in opts && opts.order_by != null) {
		params.push(`order_by=${opts.order_by}`);
	}
	if ('order_direction' in opts && opts.order_direction != null) {
		params.push(`order_direction=${opts.order_direction}`);
	}
	if ('filters' in opts && opts.filters.hasOwnProperty !== undefined) {
		for (let key in opts.filters) {
			opts.filters.hasOwnProperty(key) && opts.filters[key] != null && params.push(`filters[${key}]=${opts.filters[key]}`);
		}
	}

	fetch(`${API_URL}/artists${params.length > 0 ? '?' + params.join('&') : ''}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then((response) => {
			// console.log('Artists response:', response);

			return response.json();
		})
		.then((data) => {
			console.log('Artists data:', data);

			typeof setterFunction === 'function' && setterFunction(data.result);
		})
		.catch((e) => {
			console.error(e);
			typeof setterFunction === 'function' && setterFunction(false);
		});
}


/**
 * Fetch the artist's detail data from the API and pass it to the setter function.
 *
 * @param {function} setterFunction - A function that will be called with the artist's data.
 * @param {string} artistSlug - The slug of the artist you want to get details for.
 */
export const getArtist = (setterFunction, artistSlug) => {
	fetch(`${API_URL}/artists/detail/${artistSlug}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then((response) => {
			// console.log('Artists response:', response);

			return response.json();
		})
		.then((data) => {
			console.log('Artists\' detail data:', data);

			typeof setterFunction === 'function' && setterFunction(data.result);
		})
		.catch((e) => {
			console.error(e);
			typeof setterFunction === 'function' && setterFunction(false);
		});
}

/**
 * It fetches the search results for the given search term.
 * @param {function} setterFunction - The function that will be called with the result of the search.
 * @param {string} searchTerm - The search term to search for.
 * @param {object} [opts] - Options for limiting results
 *
 * @return {function} Function that calls the AbortController's abort method
 */
export const getSearch = (setterFunction, searchTerm, opts = {}) => {
	let params = [];

	if ('limit' in opts && opts.limit != null) {
		params.push(`limit=${opts.limit}`);
	}
	if ('offset' in opts && opts.offset != null) {
		params.push(`offset=${opts.offset}`);
	}

	let controller = new AbortController();
	let signal = controller.signal;

	console.log('[getSearch] New controller and signal created: ', { controller, signal });

	fetch(`${API_URL}/search/${searchTerm}${params.length > 0 ? '?' + params.join('&') : ''}`, {
		signal,
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	})
		.then((response) => {
			// console.log('Searchs response:', response);

			return response.json();
		})
		.then((data) => {
			console.log('Search data:', data);

			typeof setterFunction === 'function' && setterFunction(data.result);
		})
		.catch((e) => {
			console.error(e);
			typeof setterFunction === 'function' && setterFunction(false);
		});

	return () => {
		console.log('Search abort', controller, controller.abort);
		controller.abort()
	};
}



export const formatCCFixed = (value, decimals, round = null) => {
	// console.log('Original value: ', value);
	let formatted = formatFixed(value, decimals);
	let formatted_parts = formatted.split('.');
	if (round == null) {
		round = decimals;
	}

	formatted = formatted_parts[0] + '.' + (('0.' + formatted_parts[1]) * 1).toFixed(round).substring(2).replace(/(\d)0+$/, '$1');

	// console.log('Formatted value (with ' + round + ' decimal digits, i.e., * ' + decimal_power + '): ', formatted);

	return formatted;
}

export function debounce(fn, ms) {
	let timer
	return _ => {
		clearTimeout(timer)
		timer = setTimeout(_ => {
			timer = null
			fn.apply(this, arguments)
		}, ms)
	};
}
