import React from "react"
import { Link } from "gatsby"

/* Styles */
import "./index.css"

export const CookieConsent = () => {

	/*
	const handleCookieConsentModal = (e) => {
		//var $el = e.currentTarget.parentNode.parentNode.parentNode.id;
		//console.log($el, '=> handleCookieConsentModal()');
		var $modal = document.querySelector('.cookie-consent-modal');
		console.log( $modal );
		$modal.classList.toggle("d-none");
	}
	*/

	return (
		<div id={'cookies'}>
			<input type="checkbox" id="cookie_consent_accept" name="cookie_consent_accept" className={ 'd-none' } autoComplete="off" />
			<input type="checkbox" id="cookie_consent" name="cookie_consent" className={ 'd-none' } autoComplete="off" />
			<input type="checkbox" id="open_cookie_modal" name="open_cookie_modal" className={ 'd-none' } autoComplete="off" />

			<div className={ 'cookie-consent p-3 d-flex justify-content-center align-items-center' }>
				<div className={ 'icon-cookie mx-1' }>
					<span role="img" aria-label="Cookie Consent">🍪</span>
				</div>
				<div className={ 'txt mx-2' }>
					This website uses cookies for a better experience. <label htmlFor="open_cookie_modal" aria-label="Learn more" className={'learn-more'}>Learn more</label>
				</div>
				<label htmlFor="cookie_consent" aria-label="OK" className={'btn mx-1'}>OK</label>
			</div>

			<div className={ 'cookie-consent-modal d-none' }>
				<div className={ 'modal-container container' }>
					<div className={ 'row justify-content-center align-items-center h-100' }>
						<div className={ 'col-12 cookie-desc-wrapper' }>
							<ul className={ 'cookie-desc-list' }>
								<li className={ 'cookie-desc' }>
									<div className={ 'row justify-content-center align-items-center' }>
										<div className={ 'col-8 col-sm-10' }>
											<h5>Required Cookies</h5>
											<p>Essential in navigation, access to resources and functioning of the website, which do not store personal data. These cookies cannot be disabled.</p>
										</div>
										<div className={ 'col-4 col-sm-2 d-flex justify-content-center' }>
											<input type="checkbox" id="cc_req" name="cc_req" className={ 'toggle d-none' } defaultChecked />
											<div className={ 'toggle-btn opacity-50' }>Toggle</div>
										</div>
									</div>
								</li>
								{/* <li className={ 'cookie-desc' }>
									<div className={ 'row justify-content-center align-items-center' }>
										<div className={ 'col-8 col-sm-10' }>
											<h5>Functional Cookies</h5>
											<p>Fornecem uma experiência mais personalizada no website e permitem guardar escolhas que faz ao usá-lo (ex.: idioma ou sessão).</p>
										</div>
										<div className={ 'col-4 col-sm-2 d-flex justify-content-center' }>
											<input type="checkbox" id="cc_func" name="cc_func" className={ 'toggle d-none' } defaultChecked />
											<label htmlFor="cc_func" className={ 'toggle-btn' }>Toggle</label>
										</div>
									</div>
								</li> */}
								<li className={ 'cookie-desc' }>
									<div className={ 'row justify-content-center align-items-center' }>
										<div className={ 'col-8 col-sm-10' }>
											<h5>Analytics and Performance Cookies</h5>
											<p>They collect data about how visitors use the website and help us understand preferences to provide a better service.</p>
										</div>
										<div className={ 'col-4 col-sm-2 d-flex justify-content-center' }>
											<input type="checkbox" id="cc_anpe" name="cc_anpe" className={ 'toggle d-none' } defaultChecked />
											<label htmlFor="cc_anpe" className={ 'toggle-btn' }>Toggle</label>
										</div>
									</div>
								</li>
								{/* <li className={ 'cookie-desc' }>
									<div className={ 'row justify-content-center align-items-center' }>
										<div className={ 'col-8 col-sm-10' }>
											<h5>3rd-party Cookies</h5>
											<p>Usados por plataformas terceiras e apenas para funcionalidades específicas, como a visualização de um vídeo do Vimeo ou Youtube.</p>
										</div>
										<div className={ 'col-4 col-sm-2 d-flex justify-content-center' }>
											<input type="checkbox" id="cc_3rdp" name="cc_3rdp" className={ 'toggle d-none' } defaultChecked />
											<label htmlFor="cc_3rdp" className={ 'toggle-btn' }>Toggle</label>
										</div>
									</div>
								</li> */}
								<li className={ 'cookie-desc' }>
									<div className={ 'row justify-content-center align-items-center' }>
										<div className={ 'col-12 privacy-policy' }>
											<h5>More information</h5>
											<p>To know more about about our privacy policy and your choices, read our Privacy Policy <Link to={'/privacy-policy/'} className={ '' }>here</Link>.</p>
										</div>
									</div>
								</li>

							</ul>
						</div>
						<div className={ 'col-12 align-self-end text-center' }>
							{/*<div className={ 'btn consent close' }>OK</div>*/}
							<label htmlFor="cookie_consent_accept" className={ 'btn close' } aria-label="OK">Accept</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
