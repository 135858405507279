import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { ResponsiveImage } from "../..";

/* Styles */
import "./index.css"

export const Hero = ({ isHero, hasDrops }) => {

	const [isScrolled, setIsScrolled] = useState(false);
	useEffect(() => {
		window.addEventListener('scroll', () => {
			setIsScrolled(window.scrollY > 105);
		});

		const playVideos = () => {
			let vids = document.querySelectorAll('.hero-wrapper .slider .slide-container video');

			if (!vids) {
				return;
			}

			for (var i = 0; i < vids.length; i++) {
				console.log(vids[i]);
				if (!vids[i].paused) {
					// video is already playing so do nothing
				} else {
					// video is not playing
					// so play video now
					try {
						vids[i].play();
					} catch { }
				}
			}
		}

		document.querySelector('body').addEventListener('click', playVideos);
		document.querySelector('body').addEventListener('touchstart', playVideos);

	}, []);

	/* let videoSlides = [
		{
			title: 'Camila_Nogueira_-_Polar',
			posterUrl: '/assets/img/mp4/camila_cover.png',
			videoUrl: '/assets/img/mp4/Camila_Nogueira_-_Polar.mp4'
		},
		{
			title: 'Fiumani',
			posterUrl: '/assets/img/mp4/fiumani_cover.png',
			videoUrl: '/assets/img/mp4/Fiumani.mp4'
		},
		{
			title: 'Julien_Raffin_-_La_Folie_des_Grandeurs_10',
			posterUrl: '/assets/img/mp4/julien10_cover.png',
			videoUrl: '/assets/img/mp4/Julien_Raffin_-_La_Folie_des_Grandeurs_10.mp4'
		},
		{
			title: 'Julien_Raffin_-_La_Folie_des_Grandeurs_14',
			posterUrl: '/assets/img/mp4/julien14_cover.png',
			videoUrl: '/assets/img/mp4/Julien_Raffin_-_La_Folie_des_Grandeurs_14.mp4'
		},
		{
			title: 'MAIS_MENOS_NFT1_What_do_you_have',
			posterUrl: '/assets/img/mp4/maismenos_cover.png',
			videoUrl: '/assets/img/mp4/MAIS_MENOS_NFT1_What_do_you_have.mp4'
		},
		{
			title: 'Camila_Nogueira_-_Polar',
			posterUrl: '/assets/img/mp4/camila_cover.png',
			videoUrl: '/assets/img/mp4/Camila_Nogueira_-_Polar.mp4'
		},
		{
			title: 'Fiumani',
			posterUrl: '/assets/img/mp4/fiumani_cover.png',
			videoUrl: '/assets/img/mp4/Fiumani.mp4'
		},
		{
			title: 'Julien_Raffin_-_La_Folie_des_Grandeurs_10',
			posterUrl: '/assets/img/mp4/julien10_cover.png',
			videoUrl: '/assets/img/mp4/Julien_Raffin_-_La_Folie_des_Grandeurs_10.mp4'
		},
		{
			title: 'Julien_Raffin_-_La_Folie_des_Grandeurs_14',
			posterUrl: '/assets/img/mp4/julien14_cover.png',
			videoUrl: '/assets/img/mp4/Julien_Raffin_-_La_Folie_des_Grandeurs_14.mp4'
		},
		{
			title: 'MAIS_MENOS_NFT1_What_do_you_have',
			posterUrl: '/assets/img/mp4/maismenos_cover.png',
			videoUrl: '/assets/img/mp4/MAIS_MENOS_NFT1_What_do_you_have.mp4'
		}
	]; */

	let videoSlides = [
		{
			title: 'Heteronímia - 00011',
			posterUrl: '/assets/img/mp4/heteronimia_00011.png',
			videoUrl: '/assets/img/mp4/heteronimia_00011.mp4'
		},
		{
			title: 'Heteronímia - 00023',
			posterUrl: '/assets/img/mp4/heteronimia_00023.png',
			videoUrl: '/assets/img/mp4/heteronimia_00023.mp4'
		},
		{
			title: 'Heteronímia - 00025',
			posterUrl: '/assets/img/mp4/heteronimia_00025.png',
			videoUrl: '/assets/img/mp4/heteronimia_00025.mp4'
		},
		{
			title: 'Heteronímia - 00030',
			posterUrl: '/assets/img/mp4/heteronimia_00030.png',
			videoUrl: '/assets/img/mp4/heteronimia_00030.mp4'
		},
		{
			title: 'Heteronímia - 00036',
			posterUrl: '/assets/img/mp4/heteronimia_00036.png',
			videoUrl: '/assets/img/mp4/heteronimia_00036.mp4'
		},
		{
			title: 'Heteronímia - OE',
			posterUrl: '/assets/img/mp4/heteronimia_OE.png',
			videoUrl: '/assets/img/mp4/heteronimia_OE.mp4'
		},
		{
			title: 'Heteronímia - 00011',
			posterUrl: '/assets/img/mp4/heteronimia_00011.png',
			videoUrl: '/assets/img/mp4/heteronimia_00011.mp4'
		},
		{
			title: 'Heteronímia - 00023',
			posterUrl: '/assets/img/mp4/heteronimia_00023.png',
			videoUrl: '/assets/img/mp4/heteronimia_00023.mp4'
		},
		{
			title: 'Heteronímia - 00025',
			posterUrl: '/assets/img/mp4/heteronimia_00025.png',
			videoUrl: '/assets/img/mp4/heteronimia_00025.mp4'
		},
		{
			title: 'Heteronímia - 00030',
			posterUrl: '/assets/img/mp4/heteronimia_00030.png',
			videoUrl: '/assets/img/mp4/heteronimia_00030.mp4'
		},
		{
			title: 'Heteronímia - 00036',
			posterUrl: '/assets/img/mp4/heteronimia_00036.png',
			videoUrl: '/assets/img/mp4/heteronimia_00036.mp4'
		},
		{
			title: 'Heteronímia - OE',
			posterUrl: '/assets/img/mp4/heteronimia_OE.png',
			videoUrl: '/assets/img/mp4/heteronimia_OE.mp4'
		}
	];

	/* need at least 10 slides and even*/
	videoSlides = videoSlides.concat(videoSlides);

	return (
		<div className={'container-fluid p-0' + (isHero ? ' hero-top' : '')}>
			<div className={'hero-wrapper' + (isScrolled ? ' on-scroll' : '')}>


				<div className={'hero-container container-fluid' + (hasDrops ? ' has-drops' : '')}>
					<div className={'d-flex flex-column align-items-end'}>

						<div className={'hero-main'}>
							<div className={'hero-main-content'}>
								<div className={'text-content'}>
									<p>Ruído</p>
									<p>Phygital</p>
									<p>Drop</p>
									{/* <img src={'assets/img/hero/Landing_Manifest_Text_2023_03.svg'} alt="Mint pass is coming" /> */}
									{/* <img src={'assets/img/hero/Landing_Manifest_Text.svg'} alt='hero-text' /> */}
								</div>

								{/* <div className={'txt'}>
									Collect NFTs from our exclusive selection of contemporary artists.
								</div> */}
							</div>

						</div>

						<div className={'hero-btn'}>
							{/* <Link to={'/artworks/'} className={'btn btn--primary'}>Browse &amp; Collect</Link> */}
							<a href={'https://nft.ephemeralethernal.com/ruido'} rel="noopener" className={'btn btn--primary text-start'}>Mint Now</a>
							{/* <a href={'https://nft.ephemeralethernal.com'} rel="noreferrer" className={'btn btn--primary text-start'}>Discover<br />our collections</a> */}
						</div>

					</div>
				</div>



				<div className={'slider'}>

					<div className={'slider-track'} style={{ '--hero-slider-lenght': videoSlides.length, '--hero-slider-animation-s': videoSlides.length + 40 + 's' }}>
						{videoSlides.map((video, i) => (
							<div key={i + 1} className={'slide-container'}>
								<video src={video.videoUrl} type="video/mp4" aria-label={video.title} muted loop playsInline autoPlay></video>
							</div>
						))}


						{/* <div className={'slide-down'}>
							<ResponsiveImage
								initialSrc={"/assets/img/imgs/img1.png"}
								initialSrcSet={"/assets/img/imgs/img1.png 1x, ../assets/img/imgs/img1@2x.png 2x"}
								alt={"Alt Description"}
								cssClasses={"optional css-classes as-a-string"}
								sources={[
									{
										srcset: [
											{
												src: "/assets/img/imgs/img1.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img1@2x.png",
												xd: "2x",
											}
										],
										type: "image/webp",
									},
									{
										srcset: [
											{
												src: "/assets/img/imgs/img1.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img1@2x.png",
												xd: "2x",
											}
										],
										type: "image/png",
									}
								]}
							/>
						</div>

						<div className={'slide-up'}>
							<ResponsiveImage
								initialSrc={"/assets/img/imgs/img2.png"}
								initialSrcSet={"/assets/img/imgs/img2.png 1x, ../assets/img/imgs/img2@2x.png 2x"}
								alt={"Alt Description"}
								cssClasses={"optional css-classes as-a-string"}
								sources={[
									{
										srcset: [
											{
												src: "/assets/img/imgs/img2.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img2@2x.png",
												xd: "2x",
											}
										],
										type: "image/webp",
									},
									{
										srcset: [
											{
												src: "/assets/img/imgs/img2.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img2@2x.png",
												xd: "2x",
											}
										],
										type: "image/png",
									}
								]}
							/>
						</div>
						<div className={'slide-down'}>
							<ResponsiveImage
								initialSrc={"/assets/img/imgs/img3.png"}
								initialSrcSet={"/assets/img/imgs/img3.png 1x, ../assets/img/imgs/img3@2x.png 2x"}
								alt={"Alt Description"}
								cssClasses={"optional css-classes as-a-string"}
								sources={[
									{
										srcset: [
											{
												src: "/assets/img/imgs/img3.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img3@2x.png",
												xd: "2x",
											}
										],
										type: "image/webp",
									},
									{
										srcset: [
											{
												src: "/assets/img/imgs/img3.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img3@2x.png",
												xd: "2x",
											}
										],
										type: "image/png",
									}
								]}
							/>
						</div>

						<div className={'slide-up'}>
							<ResponsiveImage
								initialSrc={"/assets/img/imgs/img4.png"}
								initialSrcSet={"/assets/img/imgs/img4.png 1x, ../assets/img/imgs/img4@2x.png 2x"}
								alt={"Alt Description"}
								cssClasses={"optional css-classes as-a-string"}
								sources={[
									{
										srcset: [
											{
												src: "/assets/img/imgs/img4.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img4@2x.png",
												xd: "2x",
											}
										],
										type: "image/webp",
									},
									{
										srcset: [
											{
												src: "/assets/img/imgs/img4.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img4@2x.png",
												xd: "2x",
											}
										],
										type: "image/png",
									}
								]}
							/>
						</div>
						<div className={'slide-down'}>
							<ResponsiveImage
								initialSrc={"/assets/img/imgs/img1.png"}
								initialSrcSet={"/assets/img/imgs/img1.png 1x, ../assets/img/imgs/img1@2x.png 2x"}
								alt={"Alt Description"}
								cssClasses={"optional css-classes as-a-string"}
								sources={[
									{
										srcset: [
											{
												src: "/assets/img/imgs/img1.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img1@2x.png",
												xd: "2x",
											}
										],
										type: "image/webp",
									},
									{
										srcset: [
											{
												src: "/assets/img/imgs/img1.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img1@2x.png",
												xd: "2x",
											}
										],
										type: "image/png",
									}
								]}
							/>
						</div>

						<div className={'slide-up'}>
							<ResponsiveImage
								initialSrc={"/assets/img/imgs/img2.png"}
								initialSrcSet={"/assets/img/imgs/img2.png 1x, ../assets/img/imgs/img2@2x.png 2x"}
								alt={"Alt Description"}
								cssClasses={"optional css-classes as-a-string"}
								sources={[
									{
										srcset: [
											{
												src: "/assets/img/imgs/img2.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img2@2x.png",
												xd: "2x",
											}
										],
										type: "image/webp",
									},
									{
										srcset: [
											{
												src: "/assets/img/imgs/img2.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img2@2x.png",
												xd: "2x",
											}
										],
										type: "image/png",
									}
								]}
							/>
						</div>
						<div className={'slide-down'}>
							<ResponsiveImage
								initialSrc={"/assets/img/imgs/img3.png"}
								initialSrcSet={"/assets/img/imgs/img3.png 1x, ../assets/img/imgs/img3@2x.png 2x"}
								alt={"Alt Description"}
								cssClasses={"optional css-classes as-a-string"}
								sources={[
									{
										srcset: [
											{
												src: "/assets/img/imgs/img3.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img3@2x.png",
												xd: "2x",
											}
										],
										type: "image/webp",
									},
									{
										srcset: [
											{
												src: "/assets/img/imgs/img3.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img3@2x.png",
												xd: "2x",
											}
										],
										type: "image/png",
									}
								]}
							/>
						</div>

						<div className={'slide-up'}>
							<ResponsiveImage
								initialSrc={"/assets/img/imgs/img4.png"}
								initialSrcSet={"/assets/img/imgs/img4.png 1x, ../assets/img/imgs/img4@2x.png 2x"}
								alt={"Alt Description"}
								cssClasses={"optional css-classes as-a-string"}
								sources={[
									{
										srcset: [
											{
												src: "/assets/img/imgs/img4.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img4@2x.png",
												xd: "2x",
											}
										],
										type: "image/webp",
									},
									{
										srcset: [
											{
												src: "/assets/img/imgs/img4.png",
												xd: "1x",
											},
											{
												src: "/assets/img/imgs/img4@2x.png",
												xd: "2x",
											}
										],
										type: "image/png",
									}
								]}
							/>
						</div> */}
					</div>

				</div>





			</div>
		</div>
	)
}
