import React from "react";
import { LazyLoad } from "../../../components"

/* Styles */
import "./index.css"

export const ResponsiveImage = ({ initialSrc, initialSrcSet, alt, sources, cssClasses, ...otherArgs }) => {
	const mimeTypes = {
		'apng': 'image/apng',
		'avif': 'image/avif',
		'gif': 'image/gif',
		'jpg': 'image/jpeg',
		'jpeg': 'image/jpeg',
		'png': 'image/png',
		'svg': 'image/svg+xml',
		'webp': 'image/webp',
	};

	let sanitizedImageSrc = initialSrc.replace(" ", "%20");

	let tagSources = [];
	if (sources !== undefined && "length" in sources) {
		for (let i = 0, l = sources.length; i < l; i++) {
			let source = sources[i];
			let src = null,
				srcSet = null,
				media = null,
				type = null;

			if ("src" in source) {
				src = source.src;
			}

			if ("srcset" in source && "length" in source.srcset) {
				srcSet = [];
				for (let ii = 0, ll = source.srcset.length; ii < ll; ii++) {
					let srcsetItem = source.srcset[ii];
					if (!("src" in srcsetItem)) {
						continue;
					}
					srcSet.push(srcsetItem.src + ("xd" in srcsetItem ? " " + srcsetItem.xd : ""));
				}
				srcSet = srcSet.join(", ");

				// infer "type"
				let ext = source.srcset[0].src.substring(source.srcset[0].src.lastIndexOf('.') + 1);
				if (ext in mimeTypes) {
					type = mimeTypes[ext];
				}
			}

			if ("media" in source) {
				media = source.media;
			}

			if ("type" in source) {
				type = source.type;
			}

			tagSources.push(<source key={`sanitizedImageSrc_${i}`} src={src} srcSet={srcSet} media={media} type={type} />);
		}
	}

	return (
		<LazyLoad key={sanitizedImageSrc} delayedCall={true} partialVisibility={true} once>
			{ ({ isVisible }) =>
				<>
					{ isVisible ?
						<picture className="responsive">

							{tagSources}

							<img
								className={cssClasses}
								alt={alt}
								srcSet={initialSrcSet}
								src={initialSrc}
							/>
						</picture>
					:
						<img
							className={cssClasses}
							alt={alt}
							src={initialSrc}
						/>
					}
				</>
			}
		</LazyLoad>
	)
}
