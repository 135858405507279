import React, { useEffect, useState }/* , { useEffect } */ from "react";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Header, SubBox, PreFooter, Footer, CookieConsent, Contacts } from "../../../components";
import { motion } from "framer-motion";

/* Component Styles */
import "./index.css"
import { getUpcomingDrops } from "../Utils";

export const Layout = ({ location, children, hasPreFooter = true }) => {

	// ToDo
	/*
	* Fetch à OpenSea para Assets

	* user	=	https://opensea.io/EphemeralEthernal
	* wallet	=	0x78e8638bbe0423e0bb3da59955ba72764a123fa5
	* APIkey	=	db4d42fddfb04a0ab238ac36ecf21852
	*/
	// const PROD_API_KEY = 'db4d42fddfb04a0ab238ac36ecf21852';
	// const USE_PROD_OS_API = true;
	// const DEV_MODE = true;
	// const openseaUrl = USE_PROD_OS_API ? 'api.opensea.io/api/v1' : 'testnets-api.opensea.io';
	// const envUrl = DEV_MODE ? encodeURIComponent('.dev.abdd.pt') : encodeURIComponent('.com');
	// const proxyURL = `https://ephemeralethernal${ envUrl }/_osProxy/proxy.php?url=${ encodeURIComponent('https://' + openseaUrl + '/assets?collection=ephemeralethernal&order_direction=desc') }`;

	// useEffect(() => {
	// 	fetch( proxyURL, {
	// 		method: 'GET',
	// 		credentials: "same-origin",
	// 		headers: {
	// 			// Authorization: `Bearer ${userToken}`,
	// 			'X-API-KEY': USE_PROD_OS_API ? PROD_API_KEY : '',
	// 			'Accept': 'application/json',
	// 			'Content-Type': 'application/json',
	// 		},
	// 	})
	// 	.then((response) => response.json())
	// 	.then((data) => {
	// 		// OpenSea data mapping
	// 		console.log('================ OPENSEA ================');
	// 		console.log(' ');

	// 		console.log('Drop data:');
	// 		console.log('- Drop name:',  data.assets[0].collection.name ? data.assets[0].collection.name : 'not available');
	// 		console.log('- Drop description:',  data.assets[0].collection.description ? data.assets[0].collection.description : 'not available');

	// 		console.log(' ');

	// 		console.log('Artwork data:');
	// 		console.log('- Artist name:',  data.assets[0].name && data.assets[0].name.indexOf(' - ') !== -1 ? data.assets[0].name.split(' - ')[0] : 'not available');
	// 		console.log('- Artwork name:',  data.assets[0].name && data.assets[0].name.indexOf(' - ') !== -1 ? data.assets[0].name.split(' - ')[1] : 'not available');
	// 		console.log('- Artwork last sale price:',  data.assets[0].last_sale ? data.assets[0].last_sale.total_price : 'not available');
	// 		console.log('- Artwork list image:',  data.assets[0].image_url ? data.assets[0].image_url : 'not available');
	// 		console.log('- Artwork detail image:',  data.assets[0].image_url ? data.assets[0].image_url : 'not available');
	// 		console.log('- Artwork OpenSea url:',  data.assets[0].permalink ? data.assets[0].permalink : 'not available');

	// 		console.log(' ');
	// 		console.log('================ /OPENSEA ================');
	// 	})
	// 	.catch((e) => {
	// 		console.log('================ OPENSEA ================');
	// 		console.error(e);
	// 		console.log('================ /OPENSEA ================');
	// 	});
	// }, []);
	/* End Fetch à OpenSea para Assets */

	const [hasDrops, setHasDrops] = useState(null);

	useEffect(() => {
		getUpcomingDrops(setHasDrops);
	}, []);

	return (
		<StaticQuery
			query={graphql`
				query SiteTitleQuery {
					site {
						siteMetadata {
							title
							menuLinks {
								name
								link
							}
						}
					}
				}
			`}
			render={data => (
				<React.Fragment>

					<Helmet
						title={data.site.siteMetadata.title}
						meta={[
							{ name: 'description', content: data.site.siteMetadata.description },
							{ name: 'keywords', content: data.site.siteMetadata.title + ', keywords' },
						]}
					>
						<link rel="preload" href="/assets/fonts/Stenzel-Bold.woff2" as="font" type="font/woff2" />
						<link rel="preload" href="/assets/fonts/Stenzel-Bold.woff" as="font" type="font/woff" />
						<link rel="preload" href="/assets/fonts/Stenzel-Bold.ttf" as="font" type="font/ttf" />
						<link rel="preload" href="/assets/fonts/Raleway-Regular.woff2" as="font" type="font/woff2" />
						<link rel="preload" href="/assets/fonts/Raleway-Regular.woff" as="font" type="font/woff" />
						<link rel="preload" href="/assets/fonts/Raleway-Regular.ttf" as="font" type="font/ttf" />
						<link rel="preload" href="/assets/fonts/Raleway-Bold.woff2" as="font" type="font/woff2" />
						<link rel="preload" href="/assets/fonts/Raleway-Bold.woff" as="font" type="font/woff" />
						<link rel="preload" href="/assets/fonts/Raleway-Bold.ttf" as="font" type="font/ttf" />

						{/* Global Styles */}

						<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet" type="text/css" crossOrigin="anonymous" />
						<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap-utilities.min.css" rel="stylesheet" type="text/css" crossOrigin="anonymous" />

						<link href="/assets/libs/select2/select2.min.css" rel="stylesheet" type="text/css" />
						<link href="/assets/css/global.css" rel="stylesheet" type="text/css" />

						{/* Global JS */}
						{/*<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" type="text/javascript"/>
						<script src="/assets/libs/yall-3.2.0/yall.min.js" type="text/javascript"/>
						<script src="/assets/libs/ethereumprice.org/widget.js" type="text/javascript" /> */}


					</Helmet>

					<Header
						siteTitle={data.site.siteMetadata.title}
						menuLinks={data.site.siteMetadata.menuLinks}
						hasPreFooter={hasPreFooter}
						hasDrops={ hasDrops }
						location={location}
					/>

					<motion.main
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{
							type: "spring",
							mass: 0.35,
							stiffness: 75,
							duration: 0.3
						}}
						className={hasDrops != null ? 'has-drops ' : ''}
					>

						{children}

						<SubBox />

						{/* <PreFooter /> */}

						<Footer menuLinks={data.site.siteMetadata.menuLinks} />

						<CookieConsent />

						<Contacts location={location} />

					</motion.main>

				</React.Fragment>
			)}
		/>
	)
}
