import React, { useEffect, useState, useRef } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import SlickSlider from "react-slick";
import { motion } from "framer-motion";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { getSearch, formatCCFixed } from "../../../components/shared/Utils";
import { ResponsiveImage } from "../../../components"
import { DEV } from "../../../constants";


/* Styles */
import "./index.css"
import moment from "moment";
import { WHITELIST_URL } from "../../../constants";

function showValidationMessage(text, type = 'error', target_selector, add_preload_to_target = false) {
	if (typeof target_selector === 'undefined') {
		alert(text);
	}

	let target = document.querySelector(target_selector);

	if (target && target.nextSibling) {
		add_preload_to_target && target.classList.add('has-preloader');
		!add_preload_to_target && target.classList.remove('has-preloader');
		target.nextSibling.classList.remove('message-type-error');
		target.nextSibling.classList.remove('message-type-success');
		target.nextSibling.classList.remove('message-type-info');
		target.nextSibling.classList.remove('message-type-warning');
		type && target.nextSibling.classList.add(`message-type-${type}`);
		add_preload_to_target && target.nextSibling.classList.add('has-preloader');
		!add_preload_to_target && target.nextSibling.classList.remove('has-preloader');
		target.nextSibling.innerHTML = text;
	}
}

export const Header = ({ siteTitle, menuLinks, hasDrops, location }) => {

	// Simulate on "componentDidMount" and "componentWillUnmount"
	useEffect(() => {
		return () => {
			document.querySelector("html").classList.remove("no-scroll");
			document.querySelector("body").classList.remove("no-scroll");
		}
	}, []);

	const [isScrolled, setIsScrolled] = useState(false);
	useEffect(() => {
		window.addEventListener('scroll', () => {
			setIsScrolled(window.scrollY > 105);
		});
	});

	const [searchChecked, setSearchChecked] = useState(false);
	const [toggleChecked, setToggleChecked] = useState(false);

	const searchInput = useRef(null);

	const handleSearchClick = () => {
		if (searchChecked) {
			//console.log(searchChecked, toggleChecked);
			setSearchChecked(!searchChecked) && setToggleChecked(!toggleChecked);

			document.querySelector("html").classList.remove("no-scroll");
			document.querySelector("body").classList.remove("no-scroll");
		} else if (toggleChecked) {
			setSearchChecked(!searchChecked);
			setToggleChecked(!toggleChecked);

			document.querySelector("html").classList.add("no-scroll");
			document.querySelector("body").classList.add("no-scroll");
		} else {
			//console.log(searchChecked, toggleChecked);
			setSearchChecked(!searchChecked) && setToggleChecked(toggleChecked);

			document.querySelector("html").classList.add("no-scroll");
			document.querySelector("body").classList.add("no-scroll");
			searchInput.current.focus();
			searchInput.current.select();
		}
		// console.log('searchChecked:', searchChecked, ' / toggleChecked:', toggleChecked);
	}

	const handleToggleClick = () => {
		if (toggleChecked) {
			setToggleChecked(!toggleChecked) && setSearchChecked(!searchChecked)

			document.querySelector("html").classList.remove("no-scroll")
			document.querySelector("body").classList.remove("no-scroll")
		} else if (searchChecked) {
			setToggleChecked(!toggleChecked)
			setSearchChecked(!searchChecked)

			document.querySelector("html").classList.add("no-scroll")
			document.querySelector("body").classList.add("no-scroll")

		} else {
			setToggleChecked(!toggleChecked) && setSearchChecked(searchChecked)

			document.querySelector("html").classList.add("no-scroll")
			document.querySelector("body").classList.add("no-scroll")
		}
	}

	var settingsUpcomingNav = {
		arrows: false,
		autoplay: true,
		autoplaySpeed: 6000,
		cssEase: 'ease-in-out',
		infinite: true,
		mobileFirst: true,
		pauseOnHover: true,
		slidesToScroll: 1,
		slidesToShow: 1,
		speed: 4000,
	};


	let newsletterEmail = useRef(null);
	const _handleSubmit = async (e) => {
		console.log('_handleSubmit fired...');
		e.preventDefault();

		showValidationMessage('Subscribing...', 'info', '#form_newsletter_menu_mobile');

		const response = await addToMailchimp(newsletterEmail.current.value);
		console.log(response);

		if (response.result === 'error') {
			showValidationMessage(response.msg, 'error', '#form_newsletter_menu_mobile');
		} else {
			showValidationMessage(response.msg, 'success', '#form_newsletter_menu_mobile');
		}
	}


	const [searchLoader, setSearchLoader] = useState(null);
	const [searchList, setSearchList] = useState([]);
	const [searchValue, setSearchValue] = useState(null);


	const [searchTimeout, setSearchTimeout] = useState(0);
	//? useState executes any function that's passed to it, so we need to pass it a function that returns the callback we want to set it to.
	const [searchAbort, setSearchAbort] = useState(_ => _ => {
		console.log('Search abort placeholder');
	});

	const onChangeSearchTerm = (evt) => {
		setSearchValue(evt.target.value);
		searchAbort();
		clearTimeout(searchTimeout);
		if (!evt.target.value || evt.target.value.length < 3) {
			setSearchLoader(null);
			setSearchList([]);
			return;
		}

		setSearchTimeout(setTimeout(() => {
			setSearchLoader(null);
			setSearchList([]);
			//? useState executes any function that's passed to it, so we need to pass it a function that returns the callback we want to set it to.
			setSearchAbort(_ => getSearch(setSearchLoader, evt.target.value, { offset: 0, limit: 12 }));
		}, 300));

	}

	useEffect(() => {
		if (searchLoader == null) {
			return;
		}
		console.log(searchLoader.i);
		setSearchList(searchList.concat(searchLoader.i));
	}, [searchLoader]);

	const onLoadMoreArtworks = () => {
		getSearch(setSearchLoader, searchValue, { offset: searchList.length, limit: 12 });
	}

	// console.log({location});

	const locationSlug = location.pathname;

	return (
		<header className={`fixed${hasDrops != null ? ' has-drops' : ''} ${locationSlug.replace(/(^\/|\/$)/g, '').replace(/\//g, '--')}`}>

			{hasDrops ? (
				<SlickSlider {...settingsUpcomingNav}>

					{hasDrops.i.map((drop) => {
						return (
							<div className={'slide'}>
								{drop.presale_start ? (
									<Link to={'/pre-sale/'}>
										<div className={'pre-sale-notification'}>
											<div className={'drops-qty'}>
												<span>&#10132;</span>&nbsp;
											</div>
											<div>Pre-sale:&nbsp;<span>{moment.utc(drop.presale_start).format('DD.MM.YYYY') + (drop.presale_end > drop.presale_start ? ' - ' + moment.utc(drop.presale_end).format('DD.MM.YYYY') : '')}</span></div>
											<div className={'d-none d-md-block'}>Buy now</div>
											<div className={'arrows'}></div>
										</div>
									</Link>
								) : (
									drop.whitelist_start ? (
										<a href={WHITELIST_URL} target={'_blank'} rel={'noopener'}>
											<div className={'whitelist-notification'}>
												<div className={'drops-qty'}>
													<span>&#10132;</span>&nbsp;
												</div>
												<div>Whitelist:&nbsp;<span>{moment.utc(drop.whitelist_start).format('DD.MM.YYYY') + (drop.whitelist_end > drop.whitelist_start ? ' - ' + moment.utc(drop.whitelist_end).format('DD.MM.YYYY') : '')}</span></div>
												<div className={'d-none d-md-block'}>Sign up now</div>
												<div className={'arrows'}></div>
											</div>
										</a>
									) : (
										<Link to={'/#upcoming_drops'}>
											<div className={'upcoming-drops-notification'}>
												<div className={'drops-qty'}>
													<span>{drop.artists.length}</span>
												</div>
												<div>Upcoming drop:&nbsp;<span>{moment.utc(drop.drop_launch).format('DD.MM.YYYY')}</span></div>
												<div className={'d-none d-md-block'}>Know more</div>
												<div className={'arrows'}></div>
											</div>
										</Link>
									)
								)}
							</div>
						);
					})}

					{hasDrops.i.map((drop) => {
						return (
							<div className={'slide'}>
								<Link to={'/#upcoming_drops'}>
									<div className={'upcoming-drops-notification'}>
										<div className={'drops-qty'}>
											<span>{drop.artists.length}</span>
										</div>
										<div>Upcoming drop:&nbsp;<span>{moment.utc(drop.drop_launch).format('DD.MM.YYYY')}</span></div>
										<div className={'d-none d-md-block'}>Know more</div>
										<div className={'arrows'}></div>
									</div>
								</Link>
							</div>
						);
					})}

				</SlickSlider>
			) : (
				<SlickSlider {...settingsUpcomingNav}>
					{/* <div className={'slide'}>
						<a href={'https://nft.ephemeralethernal.com/nft/mint-pass-2023'} target={'_blank'} rel={'noopener'}>
							<div className={'alert-and-news-notification'}>
								<div className={'drops-qty'}>
									<span>&#10132;</span>&nbsp;
								</div>
								<div>Mint pass coming 5 April</div>
								<div className={'d-none d-md-block'}>Register for whitelist</div>
								<div className={'arrows'} style={{transform: 'rotate(45deg) translateX(-25%) translateY(25%)'}}></div>
							</div>
						</a>
					</div> */}
					<div className={'slide'}>
						<Link to={'#alerts_and_news'}>
							<div className={'alert-and-news-notification'}>
								<div className={'drops-qty'}>
									<span>&#10132;</span>&nbsp;
								</div>
								<div>Subscribe to our Alerts &amp; News</div>
								<div className={'d-none d-md-block'}>Subscribe now</div>
								<div className={'arrows'}></div>
							</div>
						</Link>
					</div>
				</SlickSlider>
			)}



			<div className={'container-fluid'}>

				<div className={isScrolled ? 'header on-scroll' : 'header'}>

					{DEV ? (
						<div class="testnet notice">Running on <span>Testnet</span></div>
					) : null}

					<div className={'icons order-0 order-lg-3'}>


						{/*********** SEARCH SECTION *************/}

						{/* <div className={'w-100'} >
								<div className={'container-fluid'} style={{backgroundColor: '#fff'}}>
									<input ref={searchInput} type={'text'} placeholder={'Input your search here...'} onChange={onChangeSearchTerm} />
								</div>
							</div> */}

						<input type={'checkbox'} id={'search_menu_xs'} name={'search_menu_xs'} className={'d-none'} autoComplete={'off'} checked={searchChecked} readOnly={true} />

						<button className={'search-icon order-0 order-lg-2'} aria-label={'Menu'} onClick={handleSearchClick}>
							{/* {searchChecked ? (
								<> */}
							<div className={'search-close-icon'}>
								<div></div>
								<div></div>
							</div>
							{/* <div className={'search-close-icon'}></div> */}
							{/* 	</>
							) : ( */}
							<div className={'img-container'}>
								<img src={'/assets/img/icons/lupa_icon.svg'} alt={'magnifying-glass'} />
							</div>
							{/* )} */}
						</button>

						<div className={'search-menu'}>

							<div className={'input-container container-fluid'}>
								<input ref={searchInput} type={'text'} placeholder={'Input your search here...'} onChange={onChangeSearchTerm} />
							</div>

							<div className={'search-results'}>

								{
									(searchValue && searchValue.length >= 3) ? (
										<>
											{/* <div className={'search-bkg'}></div> */}
											<div className={'placeholder-container container-fluid d-flex align-items-center flex-column'}>
												<div className={'content-placeholder col-11 mt-4'}>
													<div className={'card-container my-4 col-12 col-md-6 col-xl-4 col-xxl-3'}>
														<div className={'card w-100'}>
															<div className={'dummy-img gradient'}></div>
															<div className={'dummy-title gradient'}>&nbsp;</div>
															<div className={'dummy-description gradient'}>&nbsp;</div>
															<div className={'dummy-artist'}>
																<div className={'circle gradient'}>&nbsp;</div>
																<div className={'artist-name gradient'}>&nbsp;</div>
															</div>
														</div>
													</div>

													<div className={'card-container my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-md-block'}>
														<div className={'card w-100'}>
															<div className={'dummy-img gradient'}>&nbsp;</div>
															<div className={'dummy-title gradient'}>&nbsp;</div>
															<div className={'dummy-description gradient'}>&nbsp;</div>
															<div className={'dummy-artist'}>
																<div className={'circle gradient'}>&nbsp;</div>
																<div className={'artist-name gradient'}>&nbsp;</div>
															</div>
														</div>
													</div>

													<div className={'card-container my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-xl-block'}>
														<div className={'card w-100'}>
															<div className={'dummy-img gradient'}>&nbsp;</div>
															<div className={'dummy-title gradient'}>&nbsp;</div>
															<div className={'dummy-description gradient'}>&nbsp;</div>
															<div className={'dummy-artist'}>
																<div className={'circle gradient'}>&nbsp;</div>
																<div className={'artist-name gradient'}>&nbsp;</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											{searchLoader != null ? (
												<>
													<motion.div
														className={''}
														style={{ opacity: 0 }}

														initial={{ opacity: 0 }}
														animate={{ opacity: 1 }}
														exit={{ opacity: 0 }}
														transition={{
															type: "spring",
															mass: 0.35,
															stiffness: 75,
															duration: 0.5,
															delay: 0
														}}
													>

														<div className={'results-container container-fluid d-flex align-items-center flex-column'}>
															<div className={'search-bkg'}></div>

															<div className={'search-title col-12 col-md-11'}>
																<div className={'title'}>
																	Sea<br />rch
																	<button className={'close-search'} aria-label={'Menu'} onClick={handleSearchClick}>
																		Close
																	</button>
																</div>

															</div>

															<div className={'col-11 cards-container mt-4'}>

																{searchLoader.i ? (

																	searchList.map((item, index) => {

																		let subtitle = null,
																			item_html = null;

																		switch (item.type) {
																			case 'drop':
																				if (index === 0 || item.type !== searchList[index - 1].type) {
																					subtitle =
																						<div className={'subtitle col-12'}>
																							Your results for Drops:
																						</div>;
																				}
																				item_html =
																					<div className={'card col-12 my-4 my-md-0'}>
																						<Link to={`/artists/detail/?${item.slug}`}>
																							<div className={'drop-separator col-12'}>
																								<div className={'date'}>
																									{moment.utc(item.date).format('DD MMM, YYYY')}
																									<br />
																								</div>
																								<div className={'drop-name my-2'}>{item.name}</div>
																								<div className={'drop'}>
																									Drop #
																									{(item.number < 10 ? '0' : '') + item.number}
																								</div>
																								<div className={'drop-redirect'}>Open this list</div>
																							</div>
																						</Link>
																					</div>;
																				break;

																			case 'artwork':
																				if (index === 0 || item.type !== searchList[index - 1].type) {
																					subtitle =
																						<div className={'subtitle sep col-12'}>
																							Search results for Artworks:
																						</div>;
																				}
																				item_html =
																					<div className={'card my-4 col-12 col-md-6 col-xl-4 col-xxl-3'}>
																						<div className={'artwork-img'}>
																							<a href={item.permalink} rel="noreferrer" target="_blank" >
																								<div className={'open-hover-fx'}>Open</div>
																								<ResponsiveImage
																									initialSrc={item.image_url}
																									alt={"Artwork Image"}
																								/>
																							</a>
																						</div>

																						<div className={'artwork-title'}>{item.short_name}</div>

																						<Link to={'/artists/detail/?' + item.artist.slug} className={'artwork-artist'}>
																							<div className={'avatar'}>
																								{item.artist.avatar_paths ? (
																									<ResponsiveImage
																										initialSrc={item.artist.avatar_paths['24']}
																										initialSrcSet={`${item.artist.avatar_paths['24']} 1x, ${item.artist.avatar_paths['48']} 2x`}
																										alt="Artist photo"
																										sources={[
																											{
																												srcset: [
																													{
																														src: item.artist.avatar_paths['24_webp'],
																														xd: "1x",
																													},
																													{
																														src: item.artist.avatar_paths['48_webp'],
																														xd: "2x",
																													}
																												],
																											},
																											{
																												srcset: [
																													{
																														src: item.artist.avatar_paths['24'],
																														xd: "1x",
																													},
																													{
																														src: item.artist.avatar_paths['48'],
																														xd: "2x",
																													}
																												],
																											}
																										]}
																									/>
																								) : (
																									item.artist.name.substr(0, 1)
																								)}
																							</div>
																							<div className={'artist-name'}>{item.artist.name}</div>
																						</Link>

																						<div className={'artwork-auction'}>
																							{item.last_price ? (<div>last price</div>) : null}
																							<div className={'bid'}>
																								<div>
																									{item.last_price ? (
																										<>
																											<div className={'fixed-with-gradient'}>{formatCCFixed(item.last_price, item.last_payment_token.decimals, 4).replace('.', ',')}
																											</div>
																											<div>{item.last_payment_token.symbol}</div>
																										</>
																									) : (
																										<a href={item.permalink} rel="noreferrer" target="_blank" className={'not-on-sale'}>make an offer</a>
																									)}
																								</div>
																								<a href={item.permalink} rel="noreferrer" target="_blank" className={'btn btn--secondary'}>OpenSea</a>
																							</div>
																						</div>
																					</div>
																					;
																				break;

																			default:
																		}

																		return (
																			<>
																				{subtitle}
																				{item_html}
																			</>
																		);

																	})

																) : (
																	<div className={'container-fluid text-center my-5'}>Unable to load assets from OpenSea</div>
																)
																}



															</div>

															{/* Load More: start */}
															<div className={'load-more my-4 col-12 col-md-11 d-flex justify-content-center' + ((searchLoader == null) || (searchLoader != null && searchList.length === searchLoader.t) ? ' d-none' : '')}>
																<button className={'btn btn--secondary'} onClick={onLoadMoreArtworks}>
																	Load More
																	<div className={'btn-arrow'}></div>
																</button>
																<div className="load-more-count">
																	{searchLoader == null ? '...' : searchLoader.t - searchList.length}
																</div>
															</div>
															{/* Load More: end */}

														</div>
													</motion.div>
												</>
											) : null}
										</>
									) : (

										searchValue != null && searchValue !== '' ? (

											<motion.div
												className={''}
												style={{ opacity: 0 }}

												initial={{ opacity: 0 }}
												animate={{ opacity: 1 }}
												exit={{ opacity: 0 }}
												transition={{
													type: "spring",
													mass: 0.35,
													stiffness: 75,
													duration: 0.2,
													delay: 0
												}}
											>
												{/* <div className={'search-bkg'}></div> */}
												<div className={'placeholder-container container-fluid d-flex align-items-center flex-column'}>
													<div className={'input-error-message'}>Give us a bit more input to search...</div>
													<div className={'content-placeholder col-11 mt-4'}>
														<div className={'card-container my-4 col-12 col-md-6 col-xl-4 col-xxl-3'}>
															<div className={'card w-100'}>
																<div className={'dummy-img gradient'}></div>
																<div className={'dummy-title gradient'}>&nbsp;</div>
																<div className={'dummy-description gradient'}>&nbsp;</div>
																<div className={'dummy-artist'}>
																	<div className={'circle gradient'}>&nbsp;</div>
																	<div className={'artist-name gradient'}>&nbsp;</div>
																</div>
															</div>
														</div>

														<div className={'card-container my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-md-block'}>
															<div className={'card w-100'}>
																<div className={'dummy-img gradient'}>&nbsp;</div>
																<div className={'dummy-title gradient'}>&nbsp;</div>
																<div className={'dummy-description gradient'}>&nbsp;</div>
																<div className={'dummy-artist'}>
																	<div className={'circle gradient'}>&nbsp;</div>
																	<div className={'artist-name gradient'}>&nbsp;</div>
																</div>
															</div>
														</div>

														<div className={'card-container my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-xl-block'}>
															<div className={'card w-100'}>
																<div className={'dummy-img gradient'}>&nbsp;</div>
																<div className={'dummy-title gradient'}>&nbsp;</div>
																<div className={'dummy-description gradient'}>&nbsp;</div>
																<div className={'dummy-artist'}>
																	<div className={'circle gradient'}>&nbsp;</div>
																	<div className={'artist-name gradient'}>&nbsp;</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</motion.div>

										) : (null)
									)
								}

							</div>

							{/* <button className={'btn btn--primary'}>
								<img src={'/assets/img/icons/arrow_icon.svg'} alt={'arrow'} />
							</button> */}

						</div>

						{/*********** SEARCH SECTION *************/}


						{hasDrops != null ? (
							<Link className={'notification-icon order-1 order-lg-1'} to={hasDrops ? '/#upcoming_drops' : '#alerts_and_news'}>
								<img src={'/assets/img/icons/bell_icon.svg'} alt={'bell'} />
							</Link>
						) : (null)}


						<div className={'user-area d-none d-lg-block order-2 order-lg-1'}>
							<Link className={'user-icon'} to={'/login/'}>
								<img src={'/assets/img/icons/user_icon.svg'} alt={'Ephemeral/Ethernal'} />
							</Link>
						</div>


						{/* <div className={'menu-list about d-none d-lg-block order-lg-0'}>
							{menuLinks.map((link) => (
								link.name === 'The Project' ?
									<Link to={`${link.link}`}>
										The Project
									</Link>
									: null
							))}
						</div> */}

					</div>

					<div className={(menuLinks.length > 10 ? 'item-extra' : '') + ' nav-menu d-none d-lg-block order-lg-1'}>
						{console.log(menuLinks.length)}
						<ul className={'menu-list d-flex justify-content-between'}>
							{menuLinks.map((link, i) => (
								i > 0 && i < 5 ?
									<li className={`primary-menu${(i === 0 ? ' drops-btn' : '')}`} key={'nav_menu_' +link.name} >
										{ link.link.indexOf('http') === 0 ?
											<a href={link.link}>{link.name}</a>
										:
											<Link to={link.link}>
												{link.name}
											</Link>
										}
									</li>
									: null
							))}
						</ul>
					</div>

					<motion.div
						className={''}
						style={{ opacity: 0 }}

						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{
							type: "spring",
							mass: 0.35,
							stiffness: 75,
							duration: 0.5,
							delay: 0.5
						}}
					>
						<Link className={'logo order-1 order-lg-0'} to={'/'}>
							<img src={'/assets/img/logo/Logo.svg'} alt={siteTitle} />
						</Link>
					</motion.div>


					<nav className={'d-flex align-items-center d-lg-none'}>

						<div className={'user-area d-lg-none'}>
							<Link className={'user-icon'} to={'/login/'}>
								<img src={'/assets/img/icons/user_icon.svg'} alt={siteTitle} />
							</Link>
						</div>

						<input type={'checkbox'} id={'nav_menu_xs'} name={'nav_menu_xs'} className={'d-none'} autoComplete={'off'} checked={toggleChecked} readOnly={true} />
						<button className={'menu-icon d-lg-none'} aria-label={'Menu'} onClick={handleToggleClick}>
							<span className={"menu-icon-line"}></span>
							<span className={"menu-icon-line"}></span>
						</button>



						{/* TOGGLE MENU */}
						<div className={'toggle-menu d-flex flex-column justify-content-between'}>
							<div>
								<ul className={'menu-list container-fluid col-10 offset-1 py-4'}>
									{menuLinks.map((link, i) => (
										i > 0 && i < 5 ?
											<li className={`primary-menu${(i === 1 ? ' drops-btn' : '')}`} key={'toggle_menu_' + link.name} >
												{ link.link.indexOf('http') === 0 ?
													<a href={link.link}>{link.name}</a>
												:
													<Link to={link.link} onClick={handleToggleClick}>
														{link.name}
													</Link>
												}
											</li>
											/* : i > 3 && i < 7 && link.name != "Login" ?
												<li className={'secondary-menu'} key={'toggle_menu_' + link.name} >
													{ link.link.indexOf('http') === 0 ?
														<a href={link.link}>{link.name}</a>
													:
														<Link to={link.link} onClick={handleToggleClick}>
															{link.name}
														</Link>
													}
												</li> */
												: null
									))}

								</ul>

								{/* <div className={'menu-list container-fluid col-10 offset-1 py-4'}>
									<Link className={'user-icon'} to={'/login/'} onClick={handleToggleClick}>
										<img src={'/assets/img/icons/user_icon.svg'} alt={'Ephemeral/Ethernal'} style={{ marginRight: 10 + 'px', display: 'inline-block' }} />Login
									</Link>
								</div> */}

								<div className={'subs-content container-fluid col-10 offset-1 d-flex flex-column'}>
									<div className={'subs-text'}>
										<div className={'title'}>Subscribe to our Alerts &amp; News</div>
									</div>
									<div className={'subs-form'}>
										<form>
											<div id={'form_newsletter_menu_mobile'} className={'input-holder d-flex align-items-center'}>
												<label>
													<input
														ref={newsletterEmail}
														type={'text'}
														name={'email'}
														placeholder={'My email'}
													/>
												</label>
												<button className={'btn btn--primary'} onClick={(e) => _handleSubmit(e)}>I'm in</button>
											</div>
											<div className={'validation-message'}></div>
										</form>
										<div className={'privacy-policy'}>
											By providing your email, you indicate that you've read and accept our <a href={'/privacy-policy'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a> and <a href={'/terms-of-service'} target={'_blank'} rel={'noreferrer'}>Terms of Service</a>.
										</div>
									</div>
								</div>


								<div className={'container-fluid col-10 offset-1'}>
									<ul className={'sub-menu d-flex justify-content-start align-items-left flex-column flex-sm-row mb-5 mb-sm-0'}>
										<li className={'social-networks mb-5'} key={'social_networks'}>
											<a href={'https://discord.gg/dtPAJHRfye'} target={'_blank'} rel={'noreferrer'}>
												<img src={'/assets/img/icons/discord_icon.svg'} alt={'Discord'} />
											</a>
											<a href={'https://twitter.com/EphemeralEther1'} target={'_blank'} rel={'noreferrer'}>
												<img src={'/assets/img/icons/Twitter_icon.svg'} alt={'Twitter'} />
											</a>
											<a href={'https://www.instagram.com/ephemeralethernal/'} target={'_blank'} rel={'noreferrer'}>
												<img src={'/assets/img/icons/Instagram_icon.svg'} alt={'Instagram'} />
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>


					</nav>

				</div>
			</div>
		</header >


	)
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}
