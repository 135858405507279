import React, { useRef } from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'

/* Styles */

import "./index.css";

function showValidationMessage(text, type = 'error', target_selector, add_preload_to_target = false) {
	if (typeof target_selector === 'undefined') {
		alert(text);
	}
	console.log(target_selector);

	let target = document.querySelector(target_selector);

	if (target && target.nextSibling) {
		add_preload_to_target && target.classList.add('has-preloader');
		!add_preload_to_target && target.classList.remove('has-preloader');
		target.nextSibling.classList.remove('message-type-error');
		target.nextSibling.classList.remove('message-type-success');
		target.nextSibling.classList.remove('message-type-info');
		target.nextSibling.classList.remove('message-type-warning');
		type && target.nextSibling.classList.add(`message-type-${type}`);
		add_preload_to_target && target.nextSibling.classList.add('has-preloader');
		!add_preload_to_target && target.nextSibling.classList.remove('has-preloader');
		target.nextSibling.innerHTML = text;
	}
}

export const SubBox = () => {

	let newsletterEmail = useRef(null);
	const _handleSubmit = async (e) => {
		console.log('_handleSubmit fired...');
		e.preventDefault();

		showValidationMessage('Subscribing...', 'info', '#form_newsletter');

		const response = await addToMailchimp(newsletterEmail.current.value);
		console.log(response);

		if (response.result === 'error') {
			showValidationMessage(response.msg, 'error', '#form_newsletter');
		} else {
			showValidationMessage(response.msg, 'success', '#form_newsletter');
		}
	}

	return (
		<>
			<a id={'alerts_and_news'} name={'alerts_and_news'} className={'anchor'}></a>

			<div className={'subscribe-box'}>
				<div className={'subs-back'}>
					<div className={'subs-content container-fluid col-10 col-md-12 col-lg-10 col-xl-8 d-md-flex'}>
						<div className={'subs-text'}>
							<div className={'title'}>Alerts<br />&amp; News</div>
							<div className={'txt'}>Keep posted about our artists, NFTs and drops. We will send our updates directly into your inbox.
							</div>
						</div>
						<div className={'subs-form'}>
							<form>
								<div id="form_newsletter" className={'input-holder d-flex align-items-center'}>
									<label>
										<input
											ref={newsletterEmail}
											type="text"
											name="email"
											placeholder="My email"
										/>
									</label>
									<button className={'btn btn--primary'} onClick={(e) => _handleSubmit(e)}>I'm in</button>
								</div>
								<div className="validation-message"></div>
							</form>
							<div className={'privacy-policy'}>
								By providing your email you indicate that you’ve read and accept our <a href={'/privacy-policy'} target={'_blank'} rel="noreferrer">Privacy Policy</a> and <a href={'/terms-of-service'} target={'_blank'} rel="noreferrer">Terms of Service</a>.
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
