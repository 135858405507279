import React from "react"
import { Link } from "gatsby"

/* Styles */
import "./index.css"

export const Footer = ({ menuLinks }) => {
	return (
		<footer className={"container-fluid bg-footer"}>

			<div className={'footer-container col-lg-10'}>

				<div className={'menu-container'}>
					<div className={'left'}>
						<div className={'menu-title'}>We</div>
						<ul className={'menu'}>
							{menuLinks.map((link, i) => (
								i > 4 && i < 8 ?
									<li className={'secondary-menu'} key={link.name} >
										{ link.link.indexOf('http') === 0 ?
											<a href={link.link}>
												{link.name}
											</a>
										:
											<Link to={link.link}>
												{link.name}
											</Link>
										}
									</li>
									: null

							))}


						</ul>
					</div>
					<div className={'right'}>
						<div className={'menu-title'}>Art</div>
						<ul className={'menu'}>
							{menuLinks.map((link, i) => (
								i > 0 && i < 5 ?
									<li className={'primary-menu'} key={link.name} >
										{ link.link.indexOf('http') === 0 ?
											<a href={link.link}>
												{link.name}
											</a>
										:
											<Link to={link.link}>
												{link.name}
											</Link>
										}
									</li>
									: null

							))}

						</ul>
					</div>
				</div>




				<div className={'social-wrapper my-4'}>
					<ul className={''}>
						<li className={'social-networks'}>
							<a href={'https://discord.gg/dtPAJHRfye'} target={'_blank'} rel={'noreferrer'}>
								<img src={'/assets/img/icons/discord_icon.svg'} alt={'Discord'} />
							</a>
							<a href={'https://twitter.com/EphemeralEther1'} target={'_blank'} rel={'noreferrer'}>
								<img src={'/assets/img/icons/twitter_icon.svg'} alt={'Twitter'} />
							</a>
							<a href={'https://www.instagram.com/ephemeralethernal/'} target={'_blank'} rel={'noreferrer'}>
								<img src={'/assets/img/icons/instagram_icon.svg'} alt={'Instagram'} />
							</a>
						</li>
					</ul>
				</div>




				<div className={'help-center my-3 my-md-2'}>
					<ul className={'help-center-list'}>
						<li>
							<a href={'/terms-of-service'} target={'_blank'} rel={'noreferrer'}>
								Terms of Service
							</a>
						</li>
						<li>
							<a href={'/privacy-policy'} target={'_blank'} rel={'noreferrer'}>
								Privacy &amp; Policy
							</a>
						</li>
					</ul>
					<ul className={'help-center-list'}>
						<li>
							<a href={'https://www.livroreclamacoes.pt'} target={'_blank'} rel={'noreferrer'}>
								Complaints Book
							</a>
						</li>
					</ul>
					<ul className={'help-center-list'}>
						<li>
							<div className="open-cc" style={{cursor:'pointer'}} onClick={ () => {
								if (document.querySelector('#cookies')) {
									document.querySelector('#cookies').classList.add('show');
									document.querySelector('#cookie_consent_accept').checked = false;
									document.querySelector('#open_cookie_modal').checked = true;
								}
							}}>
								🍪 Cookies
							</div>
						</li>
					</ul>
				</div>
				<div className={'footer-stamp text-start text-md-end'}>
					<div>
						© 2022 Ephemeral/Ethernal.<br className={'line-break'} />
						Digitally crafted by <br className={'line-break'} /><a href={'https://www.adhesivebrands.pt'} target={'_blank'} rel={'noopener'}>Adhesive / Brand+Digital</a>
					</div>
				</div>

			</div>

		</footer>
	)
}
