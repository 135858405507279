export const DEV = false;

export const API_URL = DEV ? 'https://cms.ephemeralethernal.dev.abdd.pt/api/v1' : 'https://cms.ephemeralethernal.com/api/v1';
export const WEBSITE_URL = DEV ? 'https://ephemeralethernal.dev.abdd.pt/' : 'https://www.ephemeralethernal.com/';
export const WHITELIST_URL = DEV ? 'https://whitelist.ephemeralethernal.dev.abdd.pt/' : 'https://whitelist.ephemeralethernal.com/';

export const CHAINS_BY_ID = {
	1: 'Ethereum Mainnet',
	3: 'Ethereum Testnet Ropsten',
	4: 'Ethereum Testnet Rinkeby',
	5: 'Ethereum Testnet Gorli',
	42: 'Ethereum Testnet Kovan',
	137: 'Polygon Mainnet',
	80001: 'Polygon Testnet Mumbai',
};
export const PERMITTED_CHAIN_ID = DEV ? 4 : 1;
export const PAYMENT_ADDRESS = DEV ? '0x2cac00959b14426ba35a8b0280f33ed3d09e29ea' : '0x78e8638bbE0423e0bB3DA59955BA72764a123FA5';
