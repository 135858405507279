import { Link } from 'gatsby';
import * as React from 'react';
import { useRef } from 'react';
import { API_URL } from '../../../constants';

/* Styles */
import "./index.css"

/*********************************
Utils
*********************************/
function lockInput(target_selector) {
	let e = document.querySelector(target_selector);
	if (!e) {
		return;
	}
	e.disabled = true;
	e.blur();
}
function lockInputs(target_selectors) {
	if (!('length' in target_selectors)) {
		return;
	}
	for (let i = 0, l = target_selectors.length; i < l; i++) {
		lockInput(target_selectors[i]);
	}
}

function unlockInput(target_selector) {
	if (!target_selector || target_selector.trim() === '') {
		return;
	}
	let e = document.querySelector(target_selector);
	if (!e) {
		return;
	}
	e.disabled = false;
	e.blur();
}
function unlockInputs(target_selectors) {
	if (!('length' in target_selectors)) {
		return;
	}
	for (let i = 0, l = target_selectors.length; i < l; i++) {
		unlockInput(target_selectors[i]);
	}
}

function showValidationMessage(text, type = 'error', target_selector, add_preload_to_target = false) {
	if (typeof target_selector === 'undefined') {
		alert(text);
	}
	console.log(target_selector);

	let target = document.querySelector(target_selector);

	if (target && target.nextSibling) {
		add_preload_to_target && target.classList.add('has-preloader');
		!add_preload_to_target && target.classList.remove('has-preloader');
		target.nextSibling.classList.remove('message-type-error');
		target.nextSibling.classList.remove('message-type-success');
		target.nextSibling.classList.remove('message-type-info');
		target.nextSibling.classList.remove('message-type-warning');
		type && target.nextSibling.classList.add(`message-type-${type}`);
		add_preload_to_target && target.nextSibling.classList.add('has-preloader');
		!add_preload_to_target && target.nextSibling.classList.remove('has-preloader');
		target.nextSibling.innerHTML = text;
	}
}

function clearValidationMessage(target_selector) {
	let target = document.querySelector(target_selector);

	if (target && target.nextSibling) {
		target.classList.remove('has-preloader');
		target.nextSibling.classList.remove('message-type-error');
		target.nextSibling.classList.remove('message-type-success');
		target.nextSibling.classList.remove('message-type-info');
		target.nextSibling.classList.remove('message-type-warning');
		target.nextSibling.classList.remove('has-preloader');
		target.nextSibling.innerHTML = '';
	}
}

// markup
export const Contacts = ({ location }) => {
	const locationIsContacts = typeof location !== 'undefined' && location.hash === '#contacts';
	const locationPathname = typeof location !== 'undefined' ? location.pathname : '/';

	const inputName = useRef('');
	const inputPhone = useRef('');
	const inputEmail = useRef('');
	const inputMessage = useRef('');
	const inputNewsletter = useRef(false);
	const inputTermsAndConditions = useRef(false);

	function sendContactForm() {
		let name = inputName.current.value,
		phone = inputPhone.current.value,
		email = inputEmail.current.value,
		message = inputMessage.current.value,
		newsletter = inputNewsletter.current.checked,
		termsAndConditions = inputTermsAndConditions.current.checked,
		formValid = true;

		const validEmail = new RegExp(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2}(?:[a-z0-9-]*[a-z0-9])?$/);
		const validPhone = new RegExp(/^[\d+(][\d+()x -.]+[\d]$/);

		clearValidationMessage('#input_contact_name');
		clearValidationMessage('#input_contact_phone');
		clearValidationMessage('#input_contact_email');
		clearValidationMessage('#input_contact_message');
		clearValidationMessage('#input_contact_newsletter');
		clearValidationMessage('#input_contact_terms_and_conditions');
		clearValidationMessage('#btn_contact_send');

		console.log('sendContactForm', {
			name,
			phone,
			email,
			message,
			newsletter,
			termsAndConditions,
		});

		if (!name) {
			showValidationMessage('Please tell us your name', 'error', '#input_contact_name');
			formValid = false;
		}

		if (phone && phone !== '' && !validPhone.test(phone)) {
			showValidationMessage('Please enter a valid phone number', 'error', '#input_contact_phone');
			formValid = false;
		}

		if (!email) {
			showValidationMessage('Please tell us your email', 'error', '#input_contact_email');
			formValid = false;
		}

		if (!validEmail.test(email)) {
			showValidationMessage('Please enter a valid email address', 'error', '#input_contact_email');
			formValid = false;
		}

		if (!message) {
			showValidationMessage('Please tell us something', 'error', '#input_contact_message');
			formValid = false;
		}

		if (!termsAndConditions) {
			showValidationMessage('You must accept the terms and conditions', 'error', '#input_contact_terms_and_conditions');
			formValid = false;
		}

		if (!formValid) {
			return;
		}

		let data = {
			n: name,
			p: phone,
			e: email,
			m: message,
			nl: newsletter,
			t: termsAndConditions,
		}

		showValidationMessage('Sending message...', 'info', '#btn_contact_send');
		lockInputs([
			'#input_contact_name',
			'#input_contact_phone',
			'#input_contact_email',
			'#input_contact_message',
			'#input_contact_newsletter',
			'#input_contact_terms_and_conditions',
			'#btn_contact_send',
		]);

		fetch(`${API_URL}/contact/send`, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				if (data.error) {
					console.log(data.error);

					showValidationMessage(data.error, 'error', '#btn_contact_send');
					unlockInputs([
						'#input_contact_name',
						'#input_contact_phone',
						'#input_contact_email',
						'#input_contact_message',
						'#input_contact_newsletter',
						'#input_contact_terms_and_conditions',
						'#btn_contact_send',
					]);;
				} else {
					showValidationMessage('Message sent successfully!', 'success', '#btn_contact_send');
					// setTimeout(showValidationMessage, 4000, '&nbsp;', false, '#btn_contact_send');
					unlockInputs([
						'#input_contact_name',
						'#input_contact_phone',
						'#input_contact_email',
						'#input_contact_message',
						'#input_contact_newsletter',
						'#input_contact_terms_and_conditions',
						'#btn_contact_send',
					]);;
				}
			})
			.catch((error) => {
				console.log(error);
				showValidationMessage(error.message, 'error', '#btn_contact_send');
				unlockInputs([
					'#input_contact_name',
					'#input_contact_phone',
					'#input_contact_email',
					'#input_contact_message',
					'#input_contact_newsletter',
					'#input_contact_terms_and_conditions',
					'#btn_contact_send',
				]);
			});
	}

	return (
		<div className={'modal--contact d-flex align-items-center' + (locationIsContacts ? ' show' : '')} data-modal="true">

			<div className={'contacts-container container-fluid col-10'}>
				<div className={'d-flex justify-content-between'}>

					<div className={'contacts-title col-6'}>
						LET <br className="d-lg-none" />US <br />HEAR <br className="d-lg-none" />FROM <br />YOU
					</div>

					<div className={'top-inputs col-6'}>
						<div className={'input-container'}>
							<input ref={inputName} id={'input_contact_name'} type="text" placeholder={'Tell us your name'} />
							<div className="validation-message"></div>
						</div>
						<div className={'input-container'}>
							<input ref={inputPhone} id={'input_contact_phone'} type="text" placeholder={'Phone number (optional)'} />
							<div className="validation-message"></div>
						</div>
					</div>

				</div>
				<div>

					<div className={'input-container'}>
						<input ref={inputEmail} id={'input_contact_email'} type="email" placeholder={'Your email'} />
						<div className="validation-message"></div>
					</div>

					<div className={'input-container'}>
						<textarea ref={inputMessage} id={'input_contact_message'} placeholder={'Your message'} />
						<div className="validation-message"></div>
					</div>

				</div>

				<div className={'d-flex flex-column text-start justify-content-center align-items-start'}>

					<div id={'input_contact_newsletter'} className={'subscribe-check'}>
						<label className="toggle-switch">
							<input ref={inputNewsletter} type="checkbox" />
							<span className="toggle"></span>
						</label>
						<div>I want to subscribe to Alerts &amp; News</div>
					</div>
					<div className="validation-message"></div>

					<div id={'input_contact_terms_and_conditions'} className={'privacy-policy-check'}>
						<label className="toggle-switch">
							<input ref={inputTermsAndConditions} type="checkbox" />
							<span className="toggle"></span>
						</label>
						<div>I've read and accept this website's<br/><Link to={'/privacy-policy/'}>Privacy Policy.</Link></div>
					</div>
					<div className="validation-message mt-2"></div>

				</div>

				<div className={'action-container'}>
					<div className={'w-50 text-center'}>
						<Link to={locationPathname} className="cancel-action">Cancel</Link>
					</div>
					<div className={'w-50 text-center'}>
						<button id={'btn_contact_send'} className={'btn btn--primary'} onClick={sendContactForm}>Send</button>
						<div className="validation-message"></div>
					</div>
				</div>

			</div>

		</div>
	);
}

