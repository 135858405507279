import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import SlickSlider from "react-slick";
import { ResponsiveImage } from "../../../components";
import { getArtists } from "../../../components/shared/Utils";

/* Styles */
import "/static/assets/libs/slick-carousel-1.6.0/slick.min.css";
import "./index.css";

const ListItem = (props) => {
	return <li>{props.children}</li>;
};

export const SliderArtists = (props) => {
	var settings = {
		mobileFirst: true,
		dots: true,
		infinite: false,
		arrows: false,
		slidesToShow: 4,
		slidesToScroll: 4,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			}
		]
	};

	const [artistsList, setArtistsList] = useState(null);
	useEffect(() => {
		getArtists(setArtistsList, { limit: -1 });
	}, []);

	const [slidesReady, setSlidesReady] = useState(false);
	useEffect(() => {
		if (artistsList == null) {
			return;
		}

		console.log({ artistsList });

		const artistsPerSlide = 7;

		const numOfSlides = Math.ceil((artistsList.c + (props.showTitle ? 2 : 0)) / artistsPerSlide);

		console.log({ numOfSlides });

		const artists = artistsList.i.map((artist) => {
			return (
				<Link to={'/artists/detail/?' + artist.slug}>
					<div className={'avatar'}>
						{artist.avatar_paths ? (
							<ResponsiveImage
								initialSrc={artist.avatar_paths['50']}
								initialSrcSet={`${artist.avatar_paths['50']} 1x, ${artist.avatar_paths['100']} 2x`}
								alt={'Artist photo'}
								sources={[
									{
										srcset: [
											{
												src: artist.avatar_paths['50_webp'],
												xd: "1x",
											},
											{
												src: artist.avatar_paths['100_webp'],
												xd: "2x",
											}
										],
									},
									{
										srcset: [
											{
												src: artist.avatar_paths['50'],
												xd: "1x",
											},
											{
												src: artist.avatar_paths['100'],
												xd: "2x",
											}
										],
									}
								]}
							/>
						) : (
							artist.name.substr(0, 1)
						)}
					</div>
					<div className={'artist my-auto'}>{artist.name}</div>
				</Link>
			)
		});

		let slides = [];
		for (let i = 0; i < numOfSlides; i++) {
			let thisSlideArtists = artistsPerSlide,
				thisItems = [];

			// if we have a title to show, decrease first col items by 1 and total artists by 2
			// because the title takes up the space of 2 artists
			if (i === 0 && props.showTitle) {
				thisItems.push(
					<ListItem key={'title'}>
						<div className={'title'}>Art<br />ists</div>
					</ListItem>
				);
				thisSlideArtists -= 2;
			}

			thisItems = thisItems.concat(artists.splice(0, thisSlideArtists).map((item, index) => {
				return (
					<ListItem key={'artist_' + index}>
						{item}
					</ListItem>
				)

			}));

			slides.push(
				<div key={'artist_wraper_' + (i + 1)} className={'wrapper container-fluid'}>
					<div className={'card'}>
						<ul>
							{thisItems}
						</ul>
					</div>
				</div>
			);
		}

		console.log({ slides });

		setSlidesReady(slides);

	}, [artistsList, props.showTitle]);

	return (
		<div className={'slider-artists container-fluid col-lg-10 p-0'}>

			{slidesReady === false ? (
				<div className={'container-fluid text-center my-5'}>
					Loading...
				</div>
			) : (
				<SlickSlider {...settings} className={'slider d-flex justify-content-center align-items-start text-center'}>

					{slidesReady}

				</SlickSlider>
			)}
		</div>
	)
}
